/// <reference path="_definitions/jquery.d.ts" />

$( document ).ready(function() {
    var usedLaterScript = document.createElement('script');
    usedLaterScript.src = $("#__appJS").attr("href");
    document.body.appendChild(usedLaterScript);
});

window.onpageshow = function(event) {
    if (event.persisted) {}
};
